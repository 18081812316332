import React, { useState, useEffect, useRef } from 'react';
import './Dropdown.css';

const Dropdown = ({ label, options, selectedOptions, setSelectedOptions, allowSelectAll = true, isMultiSelect = true, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle the dropdown open/close
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle selecting/deselecting an option
  const handleOptionClick = (option) => {
    if (onClick) {
      onClick(option);
    } else {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      if (isMultiSelect) {
        setSelectedOptions([...selectedOptions, option]);
      } else {
        setSelectedOptions([option]); // Single-select behavior
        setIsOpen(false); // Close dropdown after selecting in single-select mode
      }
    }
    }
  };

  // Handle "Select All"
  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]); // Deselect all
    } else {
      setSelectedOptions(options); // Select all
    }
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown-button" onClick={toggleDropdown}>
        {label}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {allowSelectAll && isMultiSelect && (
            <div className="dropdown-item">
              <input
                type="checkbox"
                checked={selectedOptions.length === options.length}
                onChange={handleSelectAll}
              />
              <label>Select All</label>
            </div>
          )}
          {options.map((option, index) => (
            <div key={index} className="dropdown-item">
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionClick(option)}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
