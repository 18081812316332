// src/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { createOAuth, fetchUserInfo, lichessHost } from './auth.js';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [oauth] = useState(createOAuth);
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      try {
        const hasAuthCode = await oauth.isReturningFromAuthServer();
        if (hasAuthCode) {
          await oauth.getAccessToken();
          const userInfo = await fetchUserInfo(oauth);
          setUser(userInfo);
          localStorage.setItem('user', JSON.stringify(userInfo));
        }
      } catch (err) {
        console.error('Initialization Error:', err);
      } finally {
        setLoading(false);
      }
    };
    initAuth();
  }, [oauth]);

  const login = async () => {
    await oauth.fetchAuthorizationCode();
  };

  const logout = async () => {
    const token = oauth.token?.value;
    if (token) {
      try {
        await fetch(`${lichessHost}/api/token`, {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (err) {
        console.error('Error revoking token:', err);
      }
    }
    setUser(null);
    localStorage.removeItem('user');
    oauth.reset();
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};