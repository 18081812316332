import React, { useEffect } from 'react';
import { AuthProvider, useAuth } from './authContext.js';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import executeQuery from './queryAPI.js';
import { faChess } from '@fortawesome/free-solid-svg-icons';
import TierSelection from './TierSelection';
import Dashboard from './Dashboard';
import SuccessPage from './successPage';
import './Dashboard.css';
import './App.css';
import './TierSelection.css';

// Custom hook for login functionality
function useLogin() {
  const { login, user, logout } = useAuth();
  const navigate = useNavigate();
  
  return React.useCallback(async () => {
    try {
      if (user) {
        await logout(); // Call logout to clear user state and any existing session
      }
      
      await login(); // Log in the user

      if (!user) {
        console.error('User object is not available after login.');
        return; // Exit if user is not set
      }

      const username = user.username; // Adjust this to match your user object structure
      const email = user.email; // Adjust this to match your user object structure

      // Check if the user exists in the dashboard_production table
      const checkUserQuery = `SELECT * FROM dashboard_production WHERE username = '${username}'`;
      const userCheckResult = await executeQuery(checkUserQuery);

      if (userCheckResult.length > 0) {
        // User exists, navigate to the dashboard
        navigate('/dashboard');
      } else {
        // User does not exist, insert into the table
        const insertUserQuery = `INSERT INTO dashboard_production (username, email, tier) VALUES ('${username}', '${email}', 'free')`;
        await executeQuery(insertUserQuery);
        // Navigate to select tier page
        navigate('/select-tier');
      }
    } catch (error) {
      console.error('Login error:', error);
      // Handle error (e.g., show a message to the user)
    }
  }, [login, user, navigate]);
}

function LoginButton() {
  const { user, loading, logout } = useAuth();
  const handleLogin = useLogin();

  const handleClick = async () => {
    await logout(); // Call logout to clear user state and any existing session
    handleLogin(); // Proceed with the login process
  };


  if (loading) return <button className="cta-button" disabled>Loading...</button>;

  return (
    <button className="cta-button" onClick={handleClick}>
      Login with Lichess
    </button>
  );
}

function LoginLink() {
  const { user } = useAuth();
  const handleLogin = useLogin();

  return (
    <a href="#" className="step-link" onClick={(e) => { e.preventDefault(); handleLogin(); }}>
      Login
    </a>
  );
}

function App() {

  const scrollToFeatures = () => {
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <img src="./images/logo.png" alt="Archetyped logo" className="logo" />
          <div className="nav-buttons">
            <a href="https://chromewebstore.google.com/detail/archetyped-know-you-your/igcflbhhhlnomjdjcmamnlmkaiaoilpj?hl=en-US"><button className="cta-button">Download Extension</button></a>
            <LoginButton />
          </div>
        </nav>
        <section className="hero">
  <div className="hero-content">
    <div className="image-container">
      <img src="/images/Landing.png" alt="Example Game w/ Archetyped" className="hero-image" />
    </div>
    <div className="text-container">
      <h2>The Archetypes</h2>
      <ul className="archetype-list">
        <li><strong className="arch">Trending</strong> - <i><strong>Playing better or worse than their elo</strong></i></li>
        <li> <strong class="performanceItems">
					<span class="performanceColorGood">Better</span>
					<span class="line"> | </span>
					<span class="performanceColorBad">Worse</span>
			</strong></li>
        <li><strong className="arch">In Flow</strong> - <i><strong>Won or lost a lot of games recently</strong></i></li>
        <li class="trending">
          <span><img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/fire_1f525.gif" alt="fire" class="emoji"></img></span>
          <span class="line"><strong> | </strong></span>
          <span><img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/snowflake_2744-fe0f.gif" alt="ice" class="emoji"></img></span></li>
        <li><strong className="arch">Wild Card</strong> - <i><strong>Tends to win or lose games they shouldn't</strong></i></li>
        <li class="trending">
          <span><img src="/images/uno.png"></img></span>
        </li>
        <li><strong className="arch">Devoted</strong> - <i><strong>Plays a ton of chess</strong></i></li>
        <li class="trending">
          <span><img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/thinking-face_1f914.gif"></img></span>
        </li>
        <li><strong className="arch">Speed</strong> - <i><strong>Plays Bullet, Blitz, or Rapid</strong></i></li>
        <li class="trending"><span><img src="/images/bullet.png" alt="bullet" class="emoji"></img></span>
                        <span class="line"><strong> | </strong></span>
                        <span><img src="/images/lightning.png" alt="blitz" class="emoji"></img></span>
                        <span class="line"><strong> | </strong></span>
                        <span><img src="/images/turtle.png" alt="rapid" class="emoji"></img></span></li>
      </ul>
      <div className="button-container">
        <button className="workButton" onClick={scrollToFeatures}>How Does It Work?</button>
      </div>
    </div>
  </div>
</section>
      </header>
      <section id="features" className="features">
        <span className="feature-title"><h2 className="step-title">How<img className="title-image" src="/images/logo.png"></img>Works</h2></span>
        <ul className="steps-list">
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong><a href="https://chromewebstore.google.com/detail/archetyped-know-you-your/igcflbhhhlnomjdjcmamnlmkaiaoilpj?hl=en-US" className="step-link">Download</a>the Google Extension</strong>
          </li>
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong>Get clear visual representations of your opponent's 'Archetypes' while playing games</strong>
          </li>
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong><LoginLink/>here with your Lichess account to access all your stats</strong>
          </li>
          <li className="step-item">
            <img className="archBullet" src="/images/arch.png"></img>
            <strong>Spot key patterns in your results and opponent's gameplay to prepare better strategies</strong>
          </li>
        </ul>
      </section>
      <footer className="app-footer">
  <div className="footer-container">
    <div className="footer-links">
      <h4>Quick Links</h4>
      <ul className="footer-nav">
        <li><a href="https://chromewebstore.google.com/detail/archetyped-know-you-your/igcflbhhhlnomjdjcmamnlmkaiaoilpj?hl=en-US"><strong>Download</strong></a></li>
        <li><LoginLink/></li>
        <li><a href="mailto:support@archetype.lol"><strong>Contact Us</strong></a></li>
      </ul>
    </div>
    {/*<div className="footer-branding">
      <img className="footer-logo" src="/images/arch.png" alt="Archetyped Logo"></img>
      <p className="footer-description">Master the game with data-driven insights. Visualize, analyze, and improve your strategies with Archetyped.</p>
    </div>*/}
  </div>

  <div className="footer-bottom">
    <p>&copy; 2024 Archetyped. All rights reserved.</p>
  </div>
</footer>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <AuthProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/select-tier" element={<TierSelection />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      </AuthProvider>
    </Router>
  );
}
