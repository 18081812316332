import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import executeQuery from './queryAPI.js';
import { useAuth } from './authContext.js';
import './successPage.css';

function SuccessPage() {
  const [customerId, setCustomer] = useState(null);
  const [subscriptionId, setSubscription] = useState(null);
  const [userTier, setTier] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataAfterDelay = setTimeout(async () => {
      try {
        const query = `SELECT customer_id, subscription_id, tier FROM dashboard_production WHERE username = '${user.username}'`;
        const result = await executeQuery(query);

        if (result && result.length > 0) {
          const userData = result[0];
          setCustomer(userData.customer_id);
          setSubscription(userData.subscription_id);
          setTier(userData.tier);
        } else {
          throw new Error('User data not found');
        }
      } catch (err) {
        setError('Failed to load subscription details');
      } finally {
        setLoading(false);
      }
    }, 3000);

    return () => clearTimeout(fetchDataAfterDelay);
  }, [user.username]);

  if (isLoading) return (
    <div className="success-page">
    <div className="loading-overlay">
      <div className="spinner"></div>
      <p>Fetching your subscription details...</p>
    </div>
    </div>
  );

  if (error) return <div>{error}</div>;

  return (
    <div className="success-page">
      <h3>🎉 Payment Successful!</h3>
      <p>
        Thank you, <strong>{user.username}</strong>! Your subscription has been upgraded to the <strong>{userTier}</strong> tier - Enjoy!
      </p>
      <button
        className="dashboard-button"
        onClick={() => navigate('/dashboard')}
      >
        Go to Dashboard
      </button>
    </div>
  );
}

export default SuccessPage;