// src/TierSelection.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import executeQuery from './queryAPI.js';
import { useAuth } from './authContext.js';
import './TierSelection.css';

// {user.username}, {user.email}
const TIERS = ['free', 'basic', 'premium'];

export const basicPlan = 
  {link: process.env.NODE_ENV === 'development' ? 'https://buy.stripe.com/aEUg2r5omaTM0Du289' : '',
   priceId: process.env.NODE_ENV === 'development' ? 'price_1QGUiqGLeqTunZclCjyLtjkH' : ''
  }

export const premiumPlan =
  {link: process.env.NODE_ENV === 'development' ? 'https://buy.stripe.com/7sI5nN1866Dw4TKfYY' : '',
   priceId: process.env.NODE_ENV === 'development' ? 'price_1QGUinGLeqTunZclNcVVzf0M' : ''
  }

function TierSelection() {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  const [userTier, setUserTier] = useState(null);

const fetchUserTier = async () => {
  if (!user || !user.username) return; // Exit if user or username is not available

  const username = user.username;
  const tierQuery = `SELECT tier FROM dashboard_production WHERE username = '${username}'`; // Adjust the table and column names as necessary

  try {
    const result = await executeQuery(tierQuery);
    console.log('Query Result:', result); // Log the entire result to check its structure

    if (result.length > 0) {
      console.log('Fetched Tier:', result[0].tier); // Log fetched tier
      setUserTier(result[0].tier); // Update state with the new tier
    } else {
      console.warn(`No tier found for user: ${username}`);
      setUserTier('free'); // Default to 'free' if user is not found
    }
  } catch (error) {
    console.error('Error fetching user tier:', error);
  }
};

// Clear the user state or relevant data on mount
useEffect(() => {
  // Fetch the user's tier when the component mounts or when the user changes
  fetchUserTier();
},);

  //const selectTier = async (tier) => {
    
    //const updateTierQuery = `UPDATE dashboard_production SET tier = '${tier}' WHERE username = '${user.username}'`;

      //try {
        //await executeQuery(updateTierQuery);
        //setUserTier(tier);
        //navigate('/dashboard');
      //} catch (error) {
        //console.error('Error updating user tier:', error);
      //}
    //};

    const getButtonText = (buttonTier) => {
      if (userTier === "free") {
        if (buttonTier === "free") return "Go to Dashboard";
        return "Upgrade";
      }
      
      if (userTier === "basic") {
        if (buttonTier === "free") return "Downgrade";
        if (buttonTier === "basic") return "Manage";
        return "Upgrade";
      }
    
      if (userTier === "premium") {
        if (buttonTier === "premium") return "Manage";
        return "Downgrade";
      }
    
      return ""; // Default case if needed
    };

  if (loading) return <div>Loading...</div>;
  if (!user) return null; // This shouldn't happen due to the useEffect, but just in case
  
  return (
    <div className="pricing-section">
  <span className="feature-title"><h2 className="step-title"><img className="title-image" src="/images/logo.png"></img>Tier Selection</h2></span>
  <div className="pricing-tiers">
    <div className="tier-card">
      <h3>Free</h3>
      <strong><p className="tier-price">$0</p></strong>
      <ul className="tier-features">
        <li><img src="images/arch.png" alt="Bullet Point"></img> Access to Archetyped data over the last 7 days</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on # of encounters with each Archetype</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on game results for each Archetype</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on the mixture of Archetypes you've encountered</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on your 'Easy win', 'Kryptonite', and 'Archnemesis'</li>
      </ul>
      <a target="_blank" href={userTier === 'free' ? null : 'https://billing.stripe.com/p/login/7sIaFredYfNs80MaEE'}
          onClick={userTier === 'free' ? () => navigate('/dashboard') : null}>
        <button className="tier-button" onClick={() => navigate('/dashboard')}>{getButtonText('free')}</button>
        </a>
    </div>
    <div className="tier-card">
      <h3>Basic</h3>
      <strong><p className="tier-price">$5/month</p></strong>
      <ul className="tier-features">
        <li><img src="images/arch.png" alt="Bullet Point"></img> Access to full Archetyped data</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Ability to filter by piece color, time control, and more</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on # of encounters with each Archetype</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on game results for each Archetype</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on the mixture of Archetypes you've encountered</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on your 'Easy win', 'Kryptonite', and 'Archnemesis'</li>
      </ul>
      <a target="_blank" href={
            userTier === 'basic' || userTier === 'premium'
              ? 'https://billing.stripe.com/p/login/7sIaFredYfNs80MaEE'
              : `https://buy.stripe.com/aEUg2r5omaTM0Du289?prefilled_email=${user?.email}`}>
      <button className="tier-button">{getButtonText('basic')}</button>
      </a>
    </div>
    <div className="tier-card">
      <h3>Premium</h3>
      <strong><p className="tier-price">$10/month</p></strong>
      <ul className="tier-features">
        <li><img src="images/arch.png" alt="Bullet Point"></img> Access to full Archetyped data</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Ability to filter by piece color, time control, and more</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on # of encounters with each Archetype</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on game results for each Archetype</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on the mixture of Archetypes you've encountered</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Insights on your 'Easy win', 'Kryptonite', and 'Archnemesis'</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Access to the times you’ve been Archetyped</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Ability to download all data as CSV</li>
        <li><img src="images/arch.png" alt="Bullet Point"></img> Priority to customer service and new feature additions</li>
      </ul>
      <a target="_blank" href={
            userTier === 'basic' || userTier === 'premium'
              ? 'https://billing.stripe.com/p/login/7sIaFredYfNs80MaEE'
              : `https://buy.stripe.com/7sI5nN1866Dw4TKfYY?prefilled_email=${user?.email}`}>
      <button className="tier-button">{getButtonText('premium')}</button>
      </a>
    </div>
  </div>
  <button className="tier-button2" onClick={() => navigate('/dashboard')}>Return to Dashboard</button>
</div>
  );
}

export default TierSelection;