import React, { useState } from 'react';
import * as ProgressPrimitive from "@radix-ui/react-progress";
import { cn } from "./lib/utils.js";

const Progress = React.forwardRef(({ className, value, color = "primary", label, ...props }, ref) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div className="relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <ProgressPrimitive.Root
          ref={ref}
          className={cn(
            "relative h-4 w-full overflow-hidden rounded-full bg-secondary transition-all duration-300 ease-in-out",
            className
          )}
          {...props}
        >
          <ProgressPrimitive.Indicator
            className={cn(
              "h-full w-full flex-1 transition-all duration-300 ease-in-out",
              `bg-${color}`
            )}
            style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
          />
        </ProgressPrimitive.Root>
        {isHovered && (
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full mt-1 px-2 py-1 bg-gray-800 text-white text-xs rounded shadow">
            {label}: {value.toFixed(1)}%
          </div>
        )}
      </div>
    );
  });
  
  Progress.displayName = ProgressPrimitive.Root.displayName;
  
  export { Progress };