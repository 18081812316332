// src/auth.js
import { OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce';

export const lichessHost = 'https://lichess.org';
export const scopes = ['email:read'];
export const clientId = 'Archetyped';
export const clientUrl = `${window.location.protocol}//${window.location.host}/dashboard`;

export const createOAuth = () => new OAuth2AuthCodePKCE({
  clientId: clientId,
  authorizationUrl: `${lichessHost}/oauth`,
  tokenUrl: `${lichessHost}/api/token`,
  redirectUrl: clientUrl,
  scopes: scopes,
  responseType: 'S256',
  onAccessTokenExpiry: (refreshAccessToken) => refreshAccessToken(),
  onInvalidGrant: console.warn,
});

export const fetchUserInfo = async (oauth) => {
  const httpClient = oauth.decorateFetchHTTPClient(window.fetch);
  try {
    const [accountRes, emailRes] = await Promise.all([
      httpClient(`${lichessHost}/api/account`),
      httpClient(`${lichessHost}/api/account/email`)
    ]);

    if (!accountRes.ok || !emailRes.ok) {
      throw new Error('Failed to fetch user info');
    }

    const accountData = await accountRes.json();
    const emailData = await emailRes.json();

    return {
      username: accountData.username,
      email: emailData.email
    };
  } catch (err) {
    console.error('Error fetching user info:', err);
    return null;
  }
};