// Popup.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Popup.css';

const Popup = ({ onClose }) => {
    const navigate = useNavigate();
  return (
    <div className="popup-overlay">
      <div className="popup-content">
      <button className="popup-close-button" onClick={onClose}>
          &times;
        </button>
        <h3>Oof, Feature Locked 🔒</h3>
        <br></br>
        <p>
          Yeahhh, looks like this one's behind a paywall. No big deal — just upgrade your tier, 
          and you’ll be back in business 🚀
        </p>
        <br></br>
        <button className="popup-upgrade-button" onClick={() => navigate('/select-tier')}>
          <strong>Upgrade</strong>
        </button>
      </div>
    </div>
  );
};

export default Popup;