import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut, Line, Bubble } from 'react-chartjs-2';
import './Dashboard.css';
import Dropdown from './Dropdown.js';
import './Dashboard.css';
import executeQuery from './queryAPI.js';
import { Card } from './card.js';
import { Badge } from './badge.js';
import { Progress } from './progress.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './authContext.js';
import Popup from './Popup.js';

// Registering necessary Chart.js components
ChartJS.register(
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend
);

const donutOptions = {
  cutout: '70%',
  plugins: {
    legend: { display: false },
  },
};

const lineOptions = {
  responsive: true,
  plugins: {
    legend: { display: true },
    tooltip: {
      callbacks: {
        label: function(tooltipItem) {
          return `ELO: ${tooltipItem.raw}`;
        }
      }
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        autoSkip: true,
        maxTicksLimit: 10
      }
    },
    y: { 
      beginAtZero: false,
      ticks: {
        stepSize: 10,
        callback: function(value) {
          // Return the number without commas
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");}
      }
    },
  },
};

const Dashboard = () => {
  const { user, loading, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [userTier, setUserTier] = useState('free');
  const [selectedTimeframe, setSelectedTimeframe] = useState(['Last Week']);
  const [selectedPieceColor, setSelectedPieceColor] = useState(['White','Black']);
const [selectedTimeControl, setSelectedTimeControl] = useState(['Bullet','Blitz','Rapid']);
const [selectedGameResult, setSelectedGameResult] = useState(['Win', 'Loss', 'Draw', 'Aborted']);

  const checkUserLogin = async () => {
    try {
      if (user) {
        const username = user.username; // Adjust this to match your user object structure
      const email = user.email; // Adjust this to match your user object structure

      // Check if the user exists in the dashboard_production table
      const checkUserQuery = `SELECT * FROM dashboard_production WHERE username = '${username}'`;
      const userCheckResult = await executeQuery(checkUserQuery);

        if (userCheckResult.length === 0) {
          const insertUserQuery = `INSERT INTO dashboard_production (username, email, tier) VALUES ('${username}', '${email}', 'free')`;
        await executeQuery(insertUserQuery);
          navigate('/dashboard'); // Redirect after successful insertion
        } else {
          navigate('/dashboard'); // Redirect after successful insertion
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      // Optionally, display an error message to the user
    }
  };

  useEffect(() => {
    const tryLoginMultipleTimes = async () => {
      for (let attempt = 1; attempt <= 3; attempt++) {
        if (user) { // Only run checkUserLogin if 'user' is defined
          await checkUserLogin();
        }
        if (attempt < 3) await new Promise(res => setTimeout(res, 2000)); // Wait 2 seconds before the next attempt
      }
    };
  
    tryLoginMultipleTimes();
  }, []); // Empty dependency array to run once on component mount

  useEffect(() => {
    if (!loading && !user) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  // Function to fetch the user's tier
  const fetchUserTier = async () => {
    if (!user || !user.username) return; // Exit if user or username is not available
  
    const username = user.username;
    const tierQuery = `SELECT tier FROM dashboard_production WHERE username = '${username}'`; // Adjust the table and column names as necessary
  
    try {
      const result = await executeQuery(tierQuery);
      console.log('Query Result:', result); // Log the entire result to check its structure

      if (result.length > 0) {
        console.log('Fetched Tier:', result[0].tier); // Log fetched tier
        setUserTier(result[0].tier); // Update state with the new tier
      } else {
        console.warn(`No tier found for user: ${username}`);
        setUserTier('free'); // Default to 'free' if user is not found
      }
    } catch (error) {
      console.error('Error fetching user tier:', error);
    }
  };

 // Ensure userTier updates correctly on user or tier change
 useEffect(() => {
  fetchUserTier(); // Fetch tier when component mounts or user changes
}, [user]);

// Update timeframe when userTier changes
useEffect(() => {
  if (userTier) {
    setSelectedTimeframe(userTier === 'free' ? ['Last Week'] : ['All time']);
  }
}, [userTier]);

// Dropdown options
const timeframeOptions = ['Last Week', 'Last Month', 'Last Year', 'All time'];
const pieceColorOptions = ['White', 'Black'];
const timeControlOptions = ['Bullet', 'Blitz', 'Rapid'];
const gameResultOptions = ['Win', 'Loss', 'Draw', 'Aborted'];

const [counts, setCounts] = useState(Array.from({ length: 10 }, () => ({ totalGames: null, breakdown: {} })));
const [eloData, setEloData] = useState([]);
const [insightsData, setInsightsData] = useState([]);
const [archetypedCount, setArchetypedCount] = useState(null);
const [archetypedData, setArchetypedData] = useState(null);
const [YourarchetypedData, setYourArchetypedData] = useState(null);
const [error, setError] = useState(null);
const [showPremiumPopup, setShowPremiumPopup] = useState(false);

  // Username
  const username = user?.username;  // Mock username for now
  
  // Helper function to construct the WHERE clause based on filters
  const buildWhereClause = (additionalFilterIndex = 0) => {
  let whereClause = `WHERE user_name = '${username}'`; // Default WHERE clause with username

  // Get the current date
  const currentDate = new Date();
  let startDate = null;
  
  // Check if "All time" is not selected
  if (!selectedTimeframe.includes('All time')) {
    if (selectedTimeframe.includes('Last Week')) {
      startDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
    } else if (selectedTimeframe.includes('Last Month')) {
      startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    } else if (selectedTimeframe.includes('Last Year')) {
      startDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
    }
  }

  // Append date condition if startDate is defined
  if (startDate) {
    const formattedStartDate = startDate.toISOString().slice(0, 19).replace('T', ' ');
    whereClause += ` AND game_timestamp >= '${formattedStartDate}'`;
  }

  // Piece color filter
  if (selectedPieceColor.length > 0) {
    whereClause += ` AND color IN (${selectedPieceColor.map(color => `'${color}'`).join(', ')})`;
  }

  // Time Control filter
  if (selectedTimeControl.length > 0) {
    whereClause += ` AND time_control IN (${selectedTimeControl.map(control => `'${control}'`).join(', ')})`;
  }

  // Game Result filter
  if (selectedGameResult.length > 0) {
    whereClause += ` AND game_result IN (${selectedGameResult.map(result => `'${result}'`).join(', ')})`;
  }

  // Additional filters based on additionalFilterIndex
  const additionalFilters = [
    { condition: "trending != 0 AND trending > user_elo", index: 1 },
    { condition: "trending != 0 AND trending < user_elo", index: 2 },
    { condition: "in_flow = 'fire'", index: 3 },
    { condition: "in_flow = 'ice'", index: 4 },
    { condition: "wild_card != 0", index: 5 },
    { condition: "devoted != 0", index: 6 },
    { condition: "speed = 'bullet'", index: 7 },
    { condition: "speed = 'blitz'", index: 8 },
    { condition: "speed = 'rapid'", index: 9 },
  ];

  const additionalFilter = additionalFilters.find(filter => filter.index === additionalFilterIndex);
  if (additionalFilter) {
    whereClause += ` AND ${additionalFilter.condition}`;
  }

  return whereClause;
};

  // Fetch Game Count function
const fetchGameCount = async () => {
  const newCounts = {}; // Object to hold counts for all indices

    // Initialize counts for each archetype and game result
    for (let index = 0; index <= 9; index++) {
      newCounts[index] = { totalGames: 0, breakdown: {} }; // Create an object for each archetype index with total and breakdown

      for (const gameResult of gameResultOptions) {
        const whereClause = buildWhereClause(index) + ` AND game_result = '${gameResult}'`; // Append game result condition
        const sqlQuery = `SELECT COUNT(*) as totalGames FROM archetyped_production ${whereClause}`; // Use the generated whereClause

        try {
          const result = await executeQuery(sqlQuery);
          newCounts[index].breakdown[gameResult] = result[0].totalGames; // Store counts for this index and game result
          newCounts[index].totalGames += result[0].totalGames; // Accumulate total games
          setError(null); // Clear any previous errors
          //console.log('API Response:', newCounts); // Log the entire response
        } catch (error) {
          setError(error.message);
          newCounts[index].breakdown[gameResult] = 0; // Store zero count for this index and game result on error
        }
      }
    }

    setCounts(newCounts); // Update state with the new counts after all queries
  };

  useEffect(() => {
    fetchGameCount();
  }, [selectedTimeframe, selectedPieceColor, selectedTimeControl, selectedGameResult]);

  const generateTotalDonutData = (counts) => {
    const totalDonutDataArray = []; // Array to hold donut data for each index
  
    // Loop through each index in counts
    for (let index = 0; index <= 9; index++) {
      const breakdown = counts[index]?.breakdown || {}; // Safely access breakdown
  
      // Create the donut data structure for the current index
      const donutData = {
        labels: ['Win', 'Loss', 'Draw', 'Abort'],
        datasets: [{
          data: [
            breakdown.Win || 0,
            breakdown.Loss || 0,
            breakdown.Draw || 0,
            breakdown.Aborted || 0,
          ],
          backgroundColor: ['#A8D5BA', '#FF9A9A', '#FFE8A3', '#79A0E9'], // Softer tech-style colors
          borderColor: ['#7DAA8D', '#FF7A7A', '#E2C978', '#6C8AC6'], // Slightly darker borders
          borderWidth: 2, // Optional: Thicker borders for contrast
          hoverOffset: 6, // Hover effect
          hoverBackgroundColor: ['#8FCFA5', '#FF7B7B', '#FFD770', '#6D8ED8'], // Updated softer hover colors
        }],
      };
  
      // Store the donutData object in the array
      totalDonutDataArray[index] = donutData;
    }
  
    return totalDonutDataArray; // Return the array of donut data
  };
  
  // usage
  const totalDonutDataArray = generateTotalDonutData(counts);

  // Fetch Elo data from the database
  const fetchEloData = async () => {
    const newEloData = { value: [], timestamp: [], control: [] }; // Initialize as an object with arrays

    // Build the base SQL query with necessary where clauses based on your filters
    const whereClause = buildWhereClause(); // Ensure this function builds the correct WHERE clause based on your filters

    // SQL query to select user_elo ordered by game_timestamp
    const sqlQuery = `SELECT user_elo, game_timestamp, time_control FROM archetyped_production ${whereClause} ORDER BY game_timestamp ASC`;

    try {
      const result = await executeQuery(sqlQuery); // Execute the query

      if (result.length === 0) {
        console.warn('No data returned from the query.');
        return; // Exit early if there are no results
      }

      // Extract values into newEloData
      newEloData.value = result.map(item => item.user_elo);
      newEloData.timestamp = result.map(item => {
        const date = new Date(item.game_timestamp);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`; // Format as MM/DD/YYYY
      });
      newEloData.control = result.map(item => item.time_control);

      // Log the data to verify
      //console.log('Extracted Elo Data:', newEloData);

      // Prepare chart data
      const filteredEloValues = getEloValuesByControl(newEloData);
      setEloData(filteredEloValues); // Set state with the processed Elo values
      setError(null); // Clear any previous errors
      //console.log('API Response:', filteredEloValues); // Log the entire response
    } catch (error) {
      setError(error.message); // Handle errors
      console.error('Error fetching Elo data:', error); // Log the error
    }
  };

  // Function to filter Elo values by time control
  const getEloValuesByControl = (eloData) => {
    const eloValuesByControl = {
      bullet: [],
      blitz: [],
      rapid: []
    };

    // Loop through eloData and push values along with timestamps to the corresponding array
    for (let i = 0; i < eloData.value.length; i++) {
      const eloValue = eloData.value[i];
      const timestamp = eloData.timestamp[i];

      const entry = { elo: eloValue, timestamp }; // Create an object with elo and timestamp

      switch (eloData.control[i]) {
        case 'Bullet':
          eloValuesByControl.bullet.push(entry);
          break;
        case 'Blitz':
          eloValuesByControl.blitz.push(entry);
          break;
        case 'Rapid':
          eloValuesByControl.rapid.push(entry);
          break;
        default:
          break; // Handle unexpected time_control values if necessary
      }
    }

    return eloValuesByControl;
  };

  // Function to prepare chart data
  const prepareChartData = (filteredEloValues) => {
    const labels = []; // This will hold the timestamps for the x-axis
    const datasets = []; // This will hold the datasets for the chart

    // Prepare dataset for each control type
    Object.keys(filteredEloValues).forEach(control => {
      const data = filteredEloValues[control].map(entry => entry.elo); // Elo values for this control
      const timestamps = filteredEloValues[control].map(entry => entry.timestamp); // Corresponding timestamps

      datasets.push({
        label: control.charAt(0).toUpperCase() + control.slice(1), // Capitalize the label
        data,
        fill: false,
        borderColor: control === 'bullet' ? '#FF69B4' : // Hot Pink
        control === 'blitz' ? '#BA55D3' : // Medium Orchid
        control === 'rapid' ? '#9370DB' : // Medium Purple
        '#000000', // Fallback color (black) if none match
        backgroundColor: 'transparent',
      });

      // Merge timestamps into the labels array (assuming all arrays have the same length)
      if (labels.length === 0) {
        labels.push(...timestamps);
      }
    });

    return { labels, datasets };
  };

  useEffect(() => {
    fetchEloData(); // Call fetchEloData when the component mounts or updates
  }, []);

  useEffect(() => {
    fetchEloData(); // Call fetchEloData when the component mounts or updates
  }, [selectedTimeframe, selectedPieceColor, selectedTimeControl, selectedGameResult]);

  // Prepare data for the chart
  const eloChartData = prepareChartData(eloData);

  //Fetch archetype combo data from database
  const fetchInsightsData = async () => {
    const newInsightsData = []; // Initialize an empty array for insights data

    // Build the base SQL query with necessary where clauses based on your filters
    const whereClause = buildWhereClause(); // Ensure this function builds the correct WHERE clause based on your filters

    // SQL query for insights data (condition_code and aggregate results)
    const sqlQuery = `SELECT CONCAT(CASE WHEN trending > user_elo THEN '1' WHEN trending < user_elo AND trending != 0 THEN '2' ELSE '' END, CASE WHEN in_flow = 'fire' THEN '3' ELSE '' END, CASE WHEN in_flow = 'ice' THEN '4' ELSE '' END, CASE WHEN wild_card != 0 THEN '5' ELSE '' END, CASE WHEN devoted != 0 THEN '6' ELSE '' END, CASE WHEN speed = 'bullet' THEN '7' WHEN speed = 'blitz' THEN '8' WHEN speed = 'rapid' THEN '9' ELSE '' END) AS condition_code, COUNT(*) AS total_occurrences, SUM(CASE WHEN game_result = 'Win' THEN 1 ELSE 0 END) AS wins, SUM(CASE WHEN game_result = 'Loss' THEN 1 ELSE 0 END) AS losses, SUM(CASE WHEN game_result = 'Draw' THEN 1 ELSE 0 END) AS draws, SUM(CASE WHEN game_result = 'Aborted' THEN 1 ELSE 0 END) AS aborted, AVG(opponent_elo) AS avg_opponent_elo FROM archetyped_production ${whereClause} GROUP BY condition_code ORDER BY total_occurrences DESC, condition_code`;

    try {
      const result = await executeQuery(sqlQuery); // Execute the query

      if (result.length === 0) {
        console.warn('No data returned from the query.');
        return; // Exit early if there are no results
      }

      // Extract values into newInsightsData
      newInsightsData.push(
        ...result.map(item => ({
          archetypes: item.condition_code,
          encounters: Number(item.total_occurrences),
          winRate: Number(item.wins) / Number(item.total_occurrences),
          lossRate: Number(item.losses) / Number(item.total_occurrences),
          drawRate: Number(item.draws) / Number(item.total_occurrences),
          abortRate: Number(item.aborted) / Number(item.total_occurrences),
          performanceRate: (Number(item.wins) + (0.5 * Number(item.draws))) / 
          ((Number(item.total_occurrences) - Number(item.aborted)) || 1), // Avoid NaN
          insight: item.avg_opponent_elo !== null && !isNaN(item.avg_opponent_elo)
      ? Number(item.avg_opponent_elo).toFixed(0) // Convert to number and format
      : 'N/A' // Handle case where avg_opponent_elo is null or not a number
        }))
      );

      // Log the data to verify
      //console.log('Extracted Insights Data:', newInsightsData);

      // Set the state with processed insights data
      setInsightsData(newInsightsData);
      setError(null); // Clear any previous errors
      //console.log('API Response:', newInsightsData); // Log the entire response
    } catch (error) {
      setError(error.message); // Handle errors
      console.error('Error fetching insights data:', error); // Log the error
    }
  };

  useEffect(() => {
    fetchInsightsData(); // Call fetchInsightsData when the component mounts or updates
  }, []);  

  useEffect(() => {
    fetchInsightsData(); // Call fetchInsightsData when the component mounts or updates
  }, [selectedTimeframe, selectedPieceColor, selectedTimeControl, selectedGameResult]);  
  
//BubbleChart
const Emojis = [
  { emoji: 'Better', isText: true, color: '#629924' }, // 1
  { emoji: 'Worse', isText: true, color: '#c33' }, // 2
  { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/fire_1f525.gif" alt="Fire" style={{ width: '24px', height: '24px' }} /> }, //3
  { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/snowflake_2744-fe0f.gif" alt="Ice" style={{ width: '24px', height: '24px' }} /> }, //4
  { emoji: <img src="/images/uno.png" alt="Wild card" style={{ width: '18px', height: 'auto' }} /> }, //5
  { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/thinking-face_1f914.gif" alt="Devoted" style={{width: '24px', height: '24px'}} /> },//6
  { emoji: <img src="/images/bullet.png" alt="Bullet" style={{ width: '24px', height: 'auto' }} /> }, //7
  { emoji: <img src="/images/lightning.png" alt="Blitz" style={{ width: '24px', height: 'auto' }} /> }, //8
  { emoji: <img src="/images/turtle.png" alt="Rapid" style={{ width: '24px', height: 'auto' }} /> }, //9
];

const getBubbleColor = (archetypes) => {
  const isHot = archetypes?.includes(1) || archetypes?.includes(3);
  const isCold = archetypes?.includes(2) || archetypes?.includes(4);

  if (archetypes && archetypes?.includes(5)) {
    return 'rgba(138, 43, 226, 0.8)'; // Wild Card (Blue-violet)
  } else if (archetypes && isHot && !isCold) {
    return 'rgba(255, 140, 0, 0.8)'; // Hot (Dark orange)
  } else if (archetypes && isCold && !isHot) {
    return 'rgba(100, 149, 237, 0.8)'; // Cold (Cornflower blue)
  } else if (archetypes && archetypes?.includes(6)) {
    return 'rgba(240, 230, 140, 0.8)'; // Studious (Khaki yellow)
  }
  return 'rgba(192, 192, 192, 0.6)'; // Neutral (Silver grey)
};

const getBubbleBorderColor = (archetypes) => {
  const isHot = archetypes?.includes(1) || archetypes?.includes(3);
  const isCold = archetypes?.includes(2) || archetypes?.includes(4);

  if (archetypes && archetypes?.includes(5)) {
    return 'rgba(75, 0, 130, 1)'; // Wild Card (Indigo)
  } else if (archetypes && isHot && !isCold) {
    return 'rgba(255, 69, 0, 1)'; // Hot (Red-orange)
  } else if (archetypes && isCold && !isHot) {
    return 'rgba(70, 130, 180, 1)'; // Cold (Steel blue)
  } else if (archetypes && archetypes?.includes(6)) {
    return 'rgba(218, 165, 32, 1)'; // Studious (Goldenrod)
  }
  return 'rgba(105, 105, 105, 1)'; // Neutral (Dim grey)
};

const bubbleData = {
  datasets: [{
    label: 'Performance Insights',
    data: insightsData.map(item => ({
      x: item.insight, // x-axis: average opponent ELO
      y: item.performanceRate, // y-axis: performance rate
      r: Math.max(5, Math.sqrt(item.encounters) * 7), // Bubble size proportional to "encounters"
      archetypes: item.archetypes,
      encounters: item.encounters,
      winRate: item.winRate,
      lossRate: item.lossRate,
      drawRate: item.drawRate,
      abortRate: item.abortRate
    })),
    backgroundColor: (context) => {
      const archetypes = context?.raw?.archetypes; // Get archetypes from data point
      return getBubbleColor(archetypes); // Call function to determine color
    },
    borderColor: (context) =>  {
      const border = context.raw?.archetypes;
      return getBubbleBorderColor(border);
    }, // Border color
    borderWidth: 1.5, // Border thickness
  }]
};

//console.log(bubbleData);

// Chart options
// Function to create or get the tooltip element
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(239, 239, 239, 1)'; // Change background color
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'black'; // Change font color
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.padding = '10px 12px'; // Add padding on the sides
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};

// Custom external tooltip handler
const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const item = tooltip.dataPoints[0].raw; // Get the first data point
    const archetypeKeys = Array.from(item.archetypes || '');

    // Create a string to hold HTML for archetype icons
    let archetypeIconsHTML = '';

    archetypeKeys.forEach(key => {
      const index = parseInt(key, 10) - 1; // Convert to zero-based index
      const archetype = Emojis[index];

      // Check if the archetype exists and create HTML accordingly
      if (archetype) {
        if (archetype.isText) {
          // For text emojis, use a span with inline styling
          archetypeIconsHTML += `<span style="color: ${archetype.color}; margin-right: 4px;">${archetype.emoji}</span>`;
        } else {
          // For image emojis, append the image tag directly
          archetypeIconsHTML += `<span style="margin-right: 4px;"><img src="${archetype.emoji.props.src}" alt="${archetype.emoji.props.alt}" style="width: 24px; height: 24px;" /></span>`;
        }
      }
    });

    tooltipEl.innerHTML = `
      <div>
        <strong>Archetypes:</strong> ${archetypeIconsHTML} <br/>
        <strong>Avg Opponent ELO:</strong> ${item.x} <br/>
        <strong>Performance Score:</strong> ${(item.y * 100).toFixed(0)}% <br/>
        <strong>Encounters:</strong> ${item.encounters} <br/>
        <strong>Win Rate:</strong> ${(item.winRate * 100).toFixed(0)}% <br/>
        <strong>Loss Rate:</strong> ${(item.lossRate * 100).toFixed(0)}% <br/>
        <strong>Draw Rate:</strong> ${(item.drawRate * 100).toFixed(0)}% <br/>
        <strong>Abort Rate:</strong> ${(item.abortRate * 100).toFixed(0)}% <br/>
      </div>
    `;
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.zIndex = 999;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
};

// Chart options

const bubbleChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'linear',
      position: 'bottom',
      title: {
        display: true,
        text: 'Average Opponent ELO',
        padding: { top: 10, bottom: 10 },
        font: {
          size: 16, // Increased font size for title
          weight: 'bold', // Bold text
          color: '#000', // Black color for text
          family: 'Quicksand', // Set the font to Quicksand
        },
      },
      grid: {
        color: 'rgba(200, 200, 200, 0.5)', // Light grid lines for better readability
      },
      ticks: {
        font: {
          size: 14, // Increased font size for ticks
          color: '#000', // Black color for tick labels
          family: 'Quicksand', // Set the font to Quicksand
        },
        callback: (value) => value.toString(),
      },
    },
    y: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Performance Score',
        padding: { top: 10, bottom: 10 },
        font: {
          size: 16, // Increased font size for title
          weight: 'bold', // Bold text
          color: '#000', // Black color for text
          family: 'Quicksand', // Set the font to Quicksand
        },
      },
      grid: {
        color: 'rgba(200, 200, 200, 0.5)', // Light grid lines for better readability
      },
      ticks: {
        font: {
          size: 14, // Increased font size for ticks
          color: '#000', // Black color for tick labels
          family: 'Quicksand', // Set the font to Quicksand
        },
        callback: (value) => (value * 100).toFixed(0) + '%', // Format Y-axis ticks as percentages
      },
    },
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false, // Disable default tooltips
      external: externalTooltipHandler, // Use custom tooltip handler
    },
  }
};

  // Filter out rows with fewer than 5 encounters
    const filteredData = insightsData.filter(combination => combination.encounters >= 5);
    
    // Function to calculate total points for a combination
    const calculatePoints = (combination) => {
        const totalNonAborted = combination.encounters - (combination.abortRate * combination.encounters); // Actual games played
        const winPoints = (combination.winRate * combination.encounters)/(totalNonAborted); // Points for wins
        const drawPoints = (0.5 * combination.drawRate * combination.encounters)/(totalNonAborted); // Points for draws
        return winPoints + drawPoints; // Total points
      };
    
    // Find the row with the highest points (based on win and draw percentage)
    const highestPoints = filteredData.reduce((prev, current) => {
      const prevPoints = calculatePoints(prev);
      const currentPoints = calculatePoints(current);
      
      if (currentPoints > prevPoints) return current; // Higher points wins
      if (currentPoints === prevPoints && current.encounters > prev.encounters) return current; // Tie-breaking by encounters
      return prev; // If no condition met, keep the previous
    }, filteredData[0]);
    
    // Function to calculate effective performance for losses
    const calculateLossPerformance = (combination) => {
        const totalNonAborted = combination.encounters - (combination.abortRate * combination.encounters); // Actual games played
        // Calculate effective loss rate
        const effectiveLossRate = combination.lossRate * (combination.encounters / totalNonAborted) + 
                                   (0.5 * combination.drawRate * (combination.encounters / totalNonAborted));
        return effectiveLossRate;
    };
    
    // Find the row with the highest effective loss performance
    const highestLoss = filteredData.reduce((prev, current) => {
        const prevPerformance = calculateLossPerformance(prev);
        const currentPerformance = calculateLossPerformance(current);
    
        // Compare performances, and use encounters as tiebreaker
        if (currentPerformance > prevPerformance) {
            return current;
        } else if (currentPerformance === prevPerformance) {
            return current.encounters > prev.encounters ? current : prev;
        } else {
            return prev;
        }
    }, insightsData[0]);
    
    // Find the row with the most encounters
    const mostEncounters = filteredData.reduce((prev, current) => 
      (current.encounters > prev.encounters ? current : prev), filteredData[0]);
    
    // Store the selected rows in an array to render the cards
    const selectedData = [highestPoints, highestLoss, mostEncounters];
    //console.log(selectedData);
    
      // Array of hardcoded descriptions
      const descriptions = [
        "Easy win: your most successful combination",
        "Kryptonite: your most difficult combination",
        "Archnemesis: your most frequent combination"
      ];

      const archetypeEmojis = [
        { emoji: 'Better', isText: true, color: '#629924' }, // 1
        { emoji: 'Worse', isText: true, color: '#c33' }, // 2
        { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/fire_1f525.gif" alt="Fire" style={{ width: '24px', height: '24px' }} /> }, //3
        { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/snowflake_2744-fe0f.gif" alt="Ice" style={{ width: '24px', height: '24px' }} /> }, //4
        { emoji: <img src="/images/uno.png" alt="Wild card" style={{ width: '18px', height: 'auto' }} /> }, //5
        { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/thinking-face_1f914.gif" alt="Devoted" style={{width: '24px', height: '24px'}} /> },//6
        { emoji: <img src="/images/bullet.png" alt="Bullet" style={{ width: '24px', height: 'auto' }} /> }, //7
        { emoji: <img src="/images/lightning.png" alt="Blitz" style={{ width: '24px', height: 'auto' }} /> }, //8
        { emoji: <img src="/images/turtle.png" alt="Rapid" style={{ width: '24px', height: 'auto' }} /> }, //9
      ];

      const fetchArchetypedCount = async () => {
        const sqlQuery = `SELECT COUNT(id) as count FROM archetyped_production WHERE opponent_name = '${username}'`;
    
        try {
          const result = await executeQuery(sqlQuery); // Replace with your actual query execution function
    
          if (result.length === 0) {
            console.warn('No data returned from the query.');
            setArchetypedCount(0); // Show 0 if no data
          } else {
            const count = result[0].count;
            setArchetypedCount(count); // Update state with the count
            //console.log(`You've been archetyped ${count} times.`);
          }
        } catch (error) {
          setError(error.message); // Handle errors
          console.error('Error fetching archetyped data:', error);
        }
      };
    
      useEffect(() => {
        fetchArchetypedCount(); // Call the function when the component mounts
      }, []);

      const fetchArchetypedData = async () => {
        const sqlQuery = `SELECT * FROM archetyped_production WHERE user_name = '${username}'`;
    
        try {
          const result = await executeQuery(sqlQuery); // Replace with your actual query execution function
    
          if (result.length === 0) {
            console.warn('No data returned from the query.');
            setArchetypedData(result); // Show 0 if no data
          } else {
            setArchetypedData(result); // Update state with the count
            //console.log(result);
          }
        } catch (error) {
          setError(error.message); // Handle errors
          console.error('Error fetching archetyped data:', error);
        }
      };
    
      useEffect(() => {
        fetchArchetypedData(); // Call the function when the component mounts
      }, []);

      const fetchYourArchetypedData = async () => {
        const sqlQuery = `SELECT * FROM archetyped_production WHERE opponent_name = '${username}'`;
    
        try {
          const result = await executeQuery(sqlQuery); // Replace with your actual query execution function
    
          if (result.length === 0) {
            console.warn('No data returned from the query.');
            setYourArchetypedData(result); // Show 0 if no data
          } else {
            setYourArchetypedData(result); // Update state with the count
            //console.log(result);
          }
        } catch (error) {
          setError(error.message); // Handle errors
          console.error('Error fetching archetyped data:', error);
        }
      };
    
      useEffect(() => {
        fetchYourArchetypedData(); // Call the function when the component mounts
      }, []);

      const downloadCSV = (data) => {
        // Create headers for CSV
        const headers = ['ID', 'Game Timestamp', 'Game ID', 'Time Control', 'Color', 'Game Result', 'Logged In User', 'Username', 'Opponent', 'User Elo', 'Opponent Elo', 'Trending', 'In Flow', 'Wild Card', 'Devoted', 'Speed'];
      
        // Map each object in the array to a CSV row
        const rows = data.map(row => [
          row.id,
          row.game_timestamp,
          row.game_id,
          row.time_control,
          row.color,
          row.game_result,
          row.logged_in_user,
          row.user_name,
          row.opponent_name,
          row.user_elo,
          row.opponent_elo,
          row.trending,
          row.in_flow,
          row.wild_card,
          row.devoted,
          row.speed
        ]);
      
        // Create CSV string
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += headers.join(',') + '\n';
        csvContent += rows.map(row => row.join(',')).join('\n');
      
        // Create a link element for download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'ArchetypedData.csv');
      
        // Trigger the download
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
      };

  return (
    <div className="dashboard-container">
      {/* Welcome Section */}
      <div className="welcome">
      <span className="dash-step-title">Welcome to your<img className="dash-title-image" src="/images/logo.png"></img>dashboard,<strong className="user">{username}</strong></span>

      <button 
    style={{
      position: 'absolute',
      top: '30px',
      right: '230px', // Positioning it slightly left of the download button
      backgroundColor: '#161925',
      fontFamily: "'Quicksand', sans-serif",
      fontSize: '12pt',
      color: '#EFEFEF',
      border: 'none',
      padding: '12px 24px',
      borderRadius: '8px',
      textDecoration: 'none',
      fontWeight: 'bold',
      transition: 'background-color 0.3s ease, transform 0.3s ease',
      cursor: 'pointer'
    }}
    onClick={() => navigate('/select-tier')}
    onMouseEnter={(e) => {
      e.target.style.backgroundColor = '#DCCCBB';
      e.target.style.color = '#161925';
      e.target.style.transform = 'translateY(-2px)';
    }}
    onMouseLeave={(e) => {
      e.target.style.backgroundColor = '#161925';
      e.target.style.color = '#EFEFEF';
      e.target.style.transform = 'translateY(0)';
    }}
  >
    Manage Subscription
  </button>

{/* Add button to download CSV */}
<button 
          style={{
            position: 'absolute',
            top: '30px',
            right: '25px',
            backgroundColor: '#161925',
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '12pt',
            color: '#EFEFEF',
            border: 'none',
            padding: '12px 24px',
            borderRadius: '8px',
            textDecoration: 'none',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
            cursor: userTier === 'premium' ? 'pointer' : 'not-allowed'
          }}
          onClick={() => {
            if (userTier === 'premium') {
              downloadCSV(archetypedData); // Dataset you're passing
            } else {
              // Show a popup to upgrade
              setShowPremiumPopup(true);
            }
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#DCCCBB';
            e.target.style.color = '#161925';
            e.target.style.transform = 'translateY(-2px)';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#161925';
            e.target.style.color = '#EFEFEF';
            e.target.style.transform = 'translateY(0)';
          }}
        >
          Download the data
        </button>
        {showPremiumPopup && (
        <Popup onClose={() => setShowPremiumPopup(false)} />
      )}
      </div>

{/* Filters as dropdowns with checkboxes */}
<div className="filters">
        <Dropdown
          label="Timeframe"
          options={timeframeOptions}
          selectedOptions={selectedTimeframe}
          setSelectedOptions={setSelectedTimeframe}
          allowSelectAll={false} // No "Select All" for this dropdown
          isMultiSelect={false}  // Single-select mode
          onClick={(option) => {
            if (userTier === 'free' && option !== 'Last Week' ) {
              setShowPremiumPopup(true);
              return false; // Prevent default behavior
            } else {
              setSelectedTimeframe([option]);
            }
          }}
        />

        <Dropdown
          label="Piece Color"
          options={pieceColorOptions}
          selectedOptions={selectedPieceColor}
          setSelectedOptions={(options) => {
            if (userTier === 'free' && options.some(option => ['White', 'Black'].includes(option))) {
              setShowPremiumPopup(true);
            } else {
              setSelectedPieceColor(options);
            }
          }}
          allowSelectAll={true} // Yes "Select All" for this dropdown
          isMultiSelect={true}  // Multi-select mode
        />

        <Dropdown
          label="Time Control"
          options={timeControlOptions}
          selectedOptions={selectedTimeControl}
          setSelectedOptions={(options) => {
            if (userTier === 'free' && options.some(option => ['Bullet', 'Blitz', 'Rapid'].includes(option))) {
              setShowPremiumPopup(true);
            } else {
              setSelectedTimeControl(options);
            }
          }}
          allowSelectAll={true} // Yes "Select All" for this dropdown
          isMultiSelect={true}  // Multi-select mode
        />

        <Dropdown
          label="Game Result"
          options={gameResultOptions}
          selectedOptions={selectedGameResult}
          setSelectedOptions={(options) => {
            if (userTier === 'free' && options.some(option => ['Win', 'Loss', 'Draw', 'Aborted'].includes(option))) {
              setShowPremiumPopup(true);
            } else {
              setSelectedGameResult(options);
            }
          }}
          allowSelectAll={true} // Yes "Select All" for this dropdown
          isMultiSelect={true}  // Multi-select mode
        />
      </div>

      {/* Top Row - Donut Chart and Elo Chart */}
      <div className="dashboard-row">
        <div className="total-donut-container">
          <Doughnut data={totalDonutDataArray[0]} options={donutOptions} />
          <div className="total-donut-label"><strong>{counts[0]?.totalGames !== null ? counts[0].totalGames : 'Loading...'}</strong><br></br>total games</div>
        </div>

        <div className="line-chart-container">
          <Line data={eloChartData} options={lineOptions} />
        </div>
      </div>

      {/* Archetypes Row */}
      <h2 className="archetypes-title2">The Archetypes</h2>
      <div className="archetypes-row">
  {/* Array of emojis and numbers for each donut */}
  {[
    { emoji: 'Better', number: counts[1]?.totalGames, isText: true, color: '#629924' }, // First item
    { emoji: 'Worse', number: counts[2]?.totalGames, isText: true, color: '#c33' }, // Second item
    { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/fire_1f525.gif" alt="Fire" style={{ width: '24px', height: '24px' }} />, number: counts[3]?.totalGames },
    { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/snowflake_2744-fe0f.gif" alt="Ice" style={{ width: '24px', height: '24px' }} />, number: counts[4]?.totalGames },
    { emoji: <img src="/images/uno.png" alt="Wild card" style={{ width: '18px', height: 'auto' }} />, number: counts[5]?.totalGames },
    { emoji: <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/thinking-face_1f914.gif" alt="Devoted" style={{width: '24px', height: '24px'}} />, number: counts[6]?.totalGames },
    { emoji: <img src="/images/bullet.png" alt="Bullet" style={{ width: '24px', height: 'auto' }} />, number: counts[7]?.totalGames },
    { emoji: <img src="/images/lightning.png" alt="Blitz" style={{ width: '24px', height: 'auto' }} />, number: counts[8]?.totalGames },
    { emoji: <img src="/images/turtle.png" alt="Rapid" style={{ width: '24px', height: 'auto' }} />, number: counts[9]?.totalGames },
  ].map((item, i) => (
    <div key={i + 1} className="donut-container">
      <Doughnut data={totalDonutDataArray[i + 1]} options={donutOptions} />
      <div className="donut-label">{item.number}</div>
      <div className="emoji-label" style={item.isText ? { color: item.color } : {}}>
        {item.isText ? item.emoji : item.emoji}
      </div>
    </div>
  ))}
</div>

<div className="archetype-insights-container">
        <h3 className="archetype-insights-title">Archetype Combination Insights</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {selectedData?.map((combination, index) => (
        <Card key={index} className="w-full">
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-2xl">
              {Array.from(combination?.archetypes || '').map((key) => {
                        const index = parseInt(key, 10) - 1; // Convert the key to a zero-based index
                        const archetype = archetypeEmojis[index];

                        // Check if the archetype exists and render accordingly
                        if (archetype) {
                            const style = {
                                color: archetype.isText ? archetype.color : undefined // Use defined color for text
                            };

                            return (
                                <span key={key} style={style} className="mr-2">
                                    {typeof archetype.emoji === 'string' ? archetype.emoji : archetype.emoji}
                                </span>
                            );
                        }

                        return key; // Fallback to the key if the archetype isn't found
                    })}
              </div>
              <Badge className="bg-gray-200 text-gray-700 px-2 py-1 rounded">
                {combination?.encounters} encounters
              </Badge>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Wins</span>
                <span>{(combination?.winRate * 100).toFixed(1)}%</span>
              </div>
              <Progress 
                value={combination?.winRate * 100}  
                color="success"
                label="Win rate"
              />
              <div className="flex justify-between">
                <span>Losses</span>
                <span>{(combination?.lossRate * 100).toFixed(1)}%</span>
              </div>
              <Progress 
                value={combination?.lossRate * 100} 
                color="danger"
                label="Loss rate"
              />
              <div className="flex justify-between">
                <span>Draws</span>
                <span>{(combination?.drawRate * 100).toFixed(1)}%</span>
              </div>
              <Progress 
                value={combination?.drawRate * 100} 
                color="warning"
                label="Draw rate"
              />
              <div className="flex justify-between">
                <span>Aborts</span>
                <span>{(combination?.abortRate * 100).toFixed(1)}%</span>
              </div>
              <Progress 
                value={combination?.abortRate * 100} 
                color="primary"
                label="Abort rate"
              />
            </div>
          </div>
          <div className="p-4 border-t">
            <p className="text-sm text-gray-600"><strong>{descriptions[index]}</strong></p>
          </div>
        </Card>
      ))}
    </div>
      </div>

      <div className="mt-8" style={{ display: 'flex', width: '100%', height: '600px' }}>
  {/* Custom Legend Section (Left) */}
  <div
    id="legend"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: '10px', // Adjust spacing between legend and chart
      width: '350px', // Set the desired width for the legend
      boxSizing: 'border-box', // Ensure padding is considered in the width
    }}
  >
    <h4 style={{
        textAlign: 'center', // Center the text horizontally
        paddingBottom: '25px',
      }}> <span style={{fontSize: '20px', textDecoration: 'underline'}}> Legend </span> <br></br><span style={{ fontSize: '12px', fontWeight: 'normal', textDecoration: 'none', fontStyle: 'italic'}}>(applied to chart in order)</span></h4>
    {/* Wild Card */}
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', paddingLeft: '30px' }}>
      <span style={{ backgroundColor: 'rgba(138, 43, 226, 0.8)', width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px', border: '2px solid rgba(75, 0, 130, 1)' }}></span>
      <span> represents <img src="/images/uno.png" alt="Wild card" style={{ width: '24px', height: '24px', marginLeft: '1px', marginRight: '1px' }} /> players</span>
    </div>

    {/* Hot (Fire) */}
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', paddingLeft: '30px' }}>
      <span style={{ backgroundColor: 'rgba(255, 140, 0, 0.8)', width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px', border: '2px solid rgba(255, 69, 0, 1)' }}></span>
      <span> represents {' '}
      <span style={{ fontStyle: 'italic', color: '#629924' }}><strong>Better</strong></span> {' '} or on <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/fire_1f525.gif" alt="Fire" style={{ width: '24px', height: '24px', marginLeft: '1px', marginRight: '1px' }} /> players</span>
    </div>

    {/* Cold (Ice) */}
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', paddingLeft: '30px' }}>
      <span style={{ backgroundColor: 'rgba(100, 149, 237, 0.8)', width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px', border: '2px solid rgba(70, 130, 180, 1)' }}></span>
      <span> represents {' '}
      <span style={{ fontStyle: 'italic', color: '#c66' }}><strong>Worse</strong></span> {' '} or <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/snowflake_2744-fe0f.gif" alt="Ice" style={{ width: '24px', height: '24px', marginLeft: '1px', marginRight: '1px' }} /> players</span>
    </div>

    {/* Studious (Devoted) */}
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', paddingLeft: '30px' }}>
      <span style={{ backgroundColor: 'rgba(240, 230, 140, 0.8)', width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px', border: '2px solid rgba(218, 165, 32, 1)' }}></span>
      <span> represents <img src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/thinking-face_1f914.gif" alt="Devoted" style={{ width: '24px', height: '24px', marginLeft: '1px', marginRight: '1px' }} />  players</span>
    </div>

    {/* Neutral */}
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', paddingLeft: '30px' }}>
      <span style={{ backgroundColor: 'rgba(192, 192, 192, 0.6)', width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px', border: '2px solid rgba(105, 105, 105, 1)' }}></span>
      <span>represents everyone else</span>
    </div>
  </div>

  {/* Bubble Chart Section (Right) */}
  <div style={{ flexGrow: 1 }}>
    <Bubble data={bubbleData} options={bubbleChartOptions} />
  </div>
</div>

<div className="archetyped-box" style={{
  width: '100%', // Full width
  backgroundColor: '#f4f4f4', // Match the background color of the dashboard
  color: '#333', // Text color to match your dashboard
  textAlign: 'center', // Center align text
  padding: '30px', // More padding for breathing room
  margin: '20px 0', // Spacing from other elements
  fontFamily: 'Quicksand, sans-serif', // Match the font of the dashboard
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow to match the subtle design
  borderRadius: '8px', // Rounded corners for a polished look
  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover effect
  cursor: 'pointer', // Make it feel clickable
  display: 'flex',
  flexDirection: 'column', // Stack the elements vertically
  alignItems: 'center',
}} 
onMouseEnter={(e) => { 
  e.currentTarget.style.transform = 'scale(1.02)';
  e.currentTarget.style.boxShadow = '0px 6px 15px rgba(0, 0, 0, 0.15)';
}}
onMouseLeave={(e) => { 
  e.currentTarget.style.transform = 'scale(1)';
  e.currentTarget.style.boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.1)';
}}
>
  <p style={{
    fontSize: '1.5rem',
    letterSpacing: '1px',
    fontWeight: 'bold',
    color: '#000000', // Bold gray color
    margin: '10px 0'
  }}>You've been Archetyped</p>
  
  {userTier !== 'premium' && archetypedCount > 0 ? (
  <p className="archetyped-count" style={{
    fontSize: '3rem', // Large font size for emphasis
    fontWeight: 'bold',
    color: '#000000', // Black text for the number
    textDecoration: 'underline', // Underline the number
    margin: '0 0 10px 0',
    filter: 'blur(5px)', // Add a blur effect
    cursor: 'pointer' // Make it look clickable
  }}>**</p>
) : (
  <p className="archetyped-count" style={{
    fontSize: '3rem', // Large font size for emphasis
    fontWeight: 'bold',
    color: '#000000', // Black text for the number
    textDecoration: 'underline', // Underline the number
    margin: '0 0 10px 0'
  }}>{archetypedCount}</p>
)}
  
  <p style={{
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#000000', // Bold gray color
    letterSpacing: '1px',
    margin: '0 0 20px 0'
  }}>times</p>
  
  <a href="#" style={{
    color: '#79a0e9', // Light blue color
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textDecoration: 'none',
    borderBottom: '2px solid #79a0e9', // Subtle underline
    paddingBottom: '3px',
    transition: 'color 0.3s ease, border-bottom 0.3s ease',
  }}
  onMouseEnter={(e) => { 
    e.currentTarget.style.color = '#5a8ecb'; // Darker blue on hover
    e.currentTarget.style.borderBottom = '2px solid #5a8ecb';
  }}
  onMouseLeave={(e) => { 
    e.currentTarget.style.color = '#79a0e9'; 
    e.currentTarget.style.borderBottom = '2px solid #79a0e9';
  }}
  onClick={(e) => {
      if (userTier === 'premium') {
        e.preventDefault(); // Prevent default anchor behavior
        downloadCSV(YourarchetypedData); // Call the function to download CSV
      } else {
        // Show a popup to upgrade
        setShowPremiumPopup(true);
      }
    }}>
  Download the games
</a>

{error && <p style={{ color: 'red' }}>{error}</p>}
</div>

    </div>
  );
};

export default Dashboard;
