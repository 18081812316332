// api.js
const executeQuery = async (sqlQuery) => {
    try {
      const response = await fetch('https://g0sqhiynh0.execute-api.us-east-2.amazonaws.com/SelectSQLQuery', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sqlQuery }), // Send the SQL query as JSON
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.details || 'Something went wrong');
      }
  
      const data = await response.json();
      return data; // Return the result
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrow the error for the calling function to handle
    }
  };
  
  export default executeQuery;
  